import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Root from './components/Root';
import Home from './components/Home';
import ErrorPage from './components/ErrorPage';
import Login from './components/Login';
import Dashboard from './components/Dashboard'
import Messages from './components/Messages';
import Settings from './components/Settings';
import RequireAuth from './components/RequireAuth';
import ListClient from './components/ListClient';
import Galerie from './components/Galerie';
import Entreprise from './components/Entreprise'
import Mentions from './components/Mentions';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/mentions-legales',
        element: <Mentions />
      },
      {
        path: '/galerie',
        element: <Galerie />
      },
      {
        path: '/admin',
        element: <Login />
      },
      {
        path: '/entreprise',
        element: <Entreprise />
      },
      {
        
        path: '/dashboard',
        element: <RequireAuth><Dashboard /></RequireAuth>,
        children: [
          {
            path: '/dashboard/clients',
            element: <RequireAuth><ListClient /></RequireAuth>
          },
          {
            path: '/dashboard/messages',
            element: <RequireAuth><Messages /></RequireAuth>
          },
          // {
          //   path: '/dashboard/settings',
          //   element: <RequireAuth><Settings /></RequireAuth>
          // },

        ]
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
