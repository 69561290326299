import { useState, useEffect } from 'react';
import React from "react";
import MenuHome from './Menu'
import { Col, Row, Image, Divider, Grid } from 'antd';
import { Row as RowBootsrap, Col as ColBootsrap, Container } from 'react-bootstrap'
import { FacebookOutlined } from '@ant-design/icons';
import ModalEnc from "./ModalEnc";
import Footer from './Footer';
const { useBreakpoint } = Grid
const Entreprise = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
    return (
        <>
            <MenuHome setModalOpen={setIsModalOpen} />
            <Container>
              <RowBootsrap style={{ marginTop: 60, paddingLeft: 60, paddingRight: 60 }}>
                <ColBootsrap>
                    <p style={{ fontSize: 24, textAlign: 'center' }}>
                        Pierrotechnic est une  entreprise de plomberie/chauffage qui propose des services de dépannage d'urgence, d'entretien chaudière, d'installation de douche et de sanitaire mais aussi la rénovation complète de salle de bain.
                    </p>
                    <p style={{ fontSize: 24, textAlign: 'center' }}>
                        Votre projet sera entièrement réalisé par nos soins : tuyauterie, électricité, placo-plâtre, carrelage, faïence, pose de meubles et autres éléments.
                    </p>
                    <p style={{ fontSize: 24, textAlign: 'center' }}>
                        Nous intervenons également sur les différentes fuites, mécanisme de WC, remplacement de ballons d'eau chaude, robinetterie...
                    </p>
                    <p style={{ fontSize: 24, textAlign: 'center' }}>
                        L'entreprise basée à Séry dans les Ardennes, opère dans un rayon de 50km.
                    </p>

                </ColBootsrap>
              </RowBootsrap>
              <h1 style={{ textAlign: 'center', paddingTop: 60 }}>Zone D'intervention</h1>
              <RowBootsrap style={{ paddingTop: 60, marginBottom: 100 }}>
                <ColBootsrap style={{ textAlign: 'center' }}>
                    <Image src={`${domain}/media/media/site/optimize/location.png`} width="50%" />
                </ColBootsrap>
              </RowBootsrap>
            </Container>
            {/* <Row style={{ paddingTop: 40 }}>
                <Col xs={0} md={6}></Col>
                <Col xs={24} md={12} style={{ textAlign: 'center', paddingTop: 40, paddingLeft: largeScreen() ? 0: 10, paddingRight: largeScreen() ? 0: 10 }} >
                    <p style={{ fontSize: 24 }}>
                        Pierrotechnic est une  entreprise de plomberie/chauffage créée en avril 2022.
                    </p>
                    <p style={{ fontSize: 24 }}>
                        Elle propose des services de dépannage d'urgence, d'entretien chaudière, d'installation de douche et de sanitaire mais aussi la rénovation complète de salle de bain.
                    </p>
                    <p style={{ fontSize: 24 }}>
                        En effet, votre projet peut être entièrement réalisé par nos soins : tuyauterie, électricité, placo-plâtre, carrelage, faïence, pose de meubles et autres éléments.
                    </p>
                    <p style={{ fontSize: 24 }}>
                        Nous intervenons également sur les différentes fuites, mécanisme de WC, remplacement de ballons d'eau chaude, robinetterie...
                    </p>
                    <p style={{ fontSize: 24 }}>
                        L'entreprise basée à Séry dans les Ardennes, opère dans un rayon de 50km.
                    </p>
                    <Divider />
                </Col>
                <Col xs={0} md={6}></Col>
            </Row>
            <Row>
                <Col xs={24} md={12} offset={largeScreen() ? 6 : 0} style={{ textAlign:'center'}}>
                    <h1>Zone D'intervention</h1>
                    <Image src="https://entiouct.sirv.com/pierrotechnic/site/location.png" width={largeScreen() ? '100%' : '90%'} />
                </Col>
            </Row> */}
            <Footer />
            {
                /**
                 * MODAL
                 */
            }
            <ModalEnc open={isModalOpen} fn={setIsModalOpen} />
        </>
    )
}

export default Entreprise