import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card, Row, Col } from 'antd';
const { Meta } = Card;

const ListClient = () => {
    const [listClient, setListClient] = useState([])
    useEffect(() => {
        const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
        axios
            .get(`${domain}/api/v1/get_all_clients`)
            .then((res) => {
                setListClient(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <div>
            <h1>Clients</h1>
            <Row>
                {
                    listClient.map((client, id) => {
                        return (
                            <Col xs={6} key={id}>
                                  <Card
                                        style={{
                                            width: '100%',
                                        }}
                                        cover={
                                            <img
                                                alt="example"
                                                src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                                            />
                                        }
                                        actions={[
                                            <SettingOutlined key="setting" />,
                                            <EditOutlined key="edit" />,
                                            <EllipsisOutlined key="ellipsis" />,
                                        ]}
                                    >
                                        <Meta
                                            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                            title={`${client.lastname} ${client.firstname}`}
                                            description={(
                                                    <div>
                                                        <div>
                                                            {client.zipcode} - {client.email}
                                                        </div>
                                                        <div>
                                                            {
                                                                client.phone !== '' ? (
                                                                    <>{client.phone}</>
                                                                ) : (
                                                                    <>No phone provided</>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        />
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default ListClient