import React, { useState } from 'react'
import { Button, Row } from 'antd'
import axios from 'axios'

const CreateImagesForIntervention = ({ idOfClient, idOfMessage, submitNext, setInterventionReference }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false)
    const handleSelected = (event) => {
        setSelectedFile(event.target.files[0])
    }
    const customRequest = () => {
        setLoading(true)
        const data = new FormData()
        data.append('File', selectedFile)
        const config = {
            "headers": {
                "content-type": 'multipart/form-data',
            },
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFTOKEN',
            withCredentials: true
        }
        const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
        axios.post(`${domain}/api/v1/intervention/${idOfClient}/${idOfMessage}/upload`, data, config).then((res) => {
            setLoading(false)
            setInterventionReference(res.data.intervention_ref)
            submitNext()
        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
        
    }
    return (
        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 40, textAlign:'center' }}>
            <h2>Uploader une photo afin de nous aider à prévoir les travaux à réalisés</h2>
            <input type="file" id="myfile" name='filename' onChange={handleSelected}/>
            <div>
                {
                    loading === false ? (
                        <Button type="default" onClick={() => customRequest()} style={{ marginTop: 80, marginRight: 30 }}>
                            Passer
                        </Button>
                    ) : (null)
                }
                <Button type="primary" onClick={() => customRequest()} style={{ marginTop: 80 }} loading={loading}>
                    Terminer
                </Button>
            </div>
        </Row>
    )
}

export default CreateImagesForIntervention