import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Checkbox } from 'antd'
import axios from 'axios'

const Login = ({ csrf }) => {
    const [error, setError] = useState("")
    const navigate = useNavigate()
    
    const onFinish = (values) => {
        const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
        const url = `${domain}/api/v1/login`
        axios({
            method:'post', 
            url: url, 
            data: { 
                user: values.username,
                password: values.password
            },
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFTOKEN',
            withCredentials: true
        })
        .then(response => {
            console.log(response)
            // faire le decodage du jwt
            if (response.data.connect === true) {
                var info = {
                    email: response.data.email,
                    username: response.data.username,
                    jwt: response.data.access,
                };
                localStorage.setItem("info", JSON.stringify(info));
                navigate('/dashboard/messages')
            } else {
                setError('invalid credentials')
                return ;
            }
        })
        .catch(error => {
            console.log("there is an error ==========")
            console.log(error)
            setError(error.message)
            return ;
        });
    }
    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
        setError(errorInfo)
    }
    return (
        <div className='login-container'>
            <h1>Administration Connection</h1>
            <div className='show-errors' style={{ marginBottom: 40 }}>
                {error}
            </div>
            <Form
                name="basic"
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                        required: true,
                        message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input placeholder='username' />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password  placeholder='password'/>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Login