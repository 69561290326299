import React from 'react'
import { Modal } from 'antd'
import Conception from './Conception';

const ModalEnc = ({ fn, open, csrf }) => {
    const handleOk = () => {
        fn(false);
    };
    const handleCancel = () => {
        fn(false);
    };
    return (
        <Modal title="Création de l'intervention" open={open} footer={null} onOk={handleOk} onCancel={handleCancel} width={800}>
            <Conception csrf={csrf} fn={fn}/>
        </Modal>        
    )
}

export default ModalEnc