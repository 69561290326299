import React, { useState, useEffect } from "react";
import { Col, Row, Carousel, Image, Grid, Divider } from "antd";
import MenuHome from './Menu'
import ModalEnc from "./ModalEnc";
import '../css/home.css'
import { FacebookOutlined, FieldTimeOutlined, SnippetsOutlined, StarFilled, ToolOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Button } from 'react-bootstrap';
import { Col as ColBootsrap, Row as RowBoostrap, Container } from 'react-bootstrap'
const { useBreakpoint } = Grid
const contentStyle = {
    height: '45vh',
    color: '#fff',
    lineHeight: '45vh',
    textAlign: 'center',
};

const Home = ({ csrf }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
    return (
        <div className="home-container">
            <MenuHome setModalOpen={setIsModalOpen} />
            <RowBoostrap>
                <ColBootsrap className="d-none d-sm-block">
                    <div style={contentStyle} className={process.env.REACT_APP_ENV === 'dev' ? 's1-photos-dev' : 's1-photos' }></div>        
                </ColBootsrap>
                <ColBootsrap className="d-sm-none">
                    <Carousel autoplay={true} dots={true}>
                        <div>
                            <div style={contentStyle} className={process.env.REACT_APP_ENV === 'dev' ? 's2-photos-dev' : 's2-photos' }></div>
                        </div>
                        <div>
                            <div style={contentStyle} className={process.env.REACT_APP_ENV === 'dev' ? 's3-photos-dev' : 's3-photos' }></div>
                        </div>
                        <div>
                            <div style={contentStyle} className={process.env.REACT_APP_ENV === 'dev' ? 's4-photos-dev' : 's4-photos' }></div>
                        </div>
                    </Carousel>                    
                </ColBootsrap>
            </RowBoostrap>
            {/* {
                largeScreen() ? (
                    <div style={contentStyle} className="s1-photos"></div>
                ) : (
                    <Carousel autoplay={true} dots={true}>
                        <div>
                            <div style={contentStyle} className="s2-photos"></div>
                        </div>
                        <div>
                            <div style={contentStyle} className="s3-photos"></div>
                        </div>
                        <div>
                            <div style={contentStyle} className="s4-photos"></div>
                        </div>
                    </Carousel>                    
                )
            } */}
            <Container fluid>
                <RowBoostrap className="" style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                    <ColBootsrap xs={24} sm={24} md={6} lg={3}>
                        <div className="card-contains">
                            <div style={{ textAlign: 'center', fontSize: 30, marginBottom: 10 }}>
                                <StarFilled />
                                <StarFilled />
                                <StarFilled />
                                <StarFilled />
                                <StarFilled />
                            </div>
                            <h2 style={{ textAlign: 'center', fontSize: 32  }}>Expérience</h2>
                            <p style={{ fontSize: 24, textAlign: 'center', paddingTop: 10 }}>
                                Plus de 20 ans d’expérience, Pierrotechnic vous assure un suivi et un travail sur mesure
                            </p>
                        </div>
                    </ColBootsrap>
                    <div style={{ marginTop: 20 }} className="d-none d-xs-block d-sm-block d-md-none d-lg-none" /> 
                    <ColBootsrap xs={24} sm={24} md={6} lg={3}>
                        <div className="card-contains">
                            <div style={{ textAlign: 'center', fontSize: 40 }}>
                                <FieldTimeOutlined />
                            </div>
                            <h2 style={{ textAlign: 'center', fontSize: 32  }}>Efficacité</h2>
                            <p style={{ fontSize: 24, textAlign: 'center', paddingTop: 10 }}>
                                Prise de RDV dans les plus brefs délais et intervention rapide
                            </p>            
                        </div>
                    </ColBootsrap>
                    <div style={{ marginTop: 20 }} className="d-none d-xs-block d-sm-block d-md-block d-lg-none" /> 
                    <ColBootsrap xs={24} sm={24} md={6} lg={3}>
                        <div className="card-contains">
                            <div style={{ textAlign: 'center', fontSize: 40 }}>
                                <ToolOutlined />
                            </div>
                            <h2 style={{ textAlign: 'center', fontSize: 32  }}>Travail soigné</h2>
                            <p style={{ fontSize: 24, paddingTop: 10, textAlign: 'center' }}>
                                A l'écoute du client, outillage adapté, exécution en toute sécurité
                            </p>                
                        </div>
                    </ColBootsrap>
                    <div style={{ marginTop: 20 }} className="d-none d-xs-block d-sm-block d-md-none d-lg-none" /> 
                    <ColBootsrap xs={24} sm={24} md={6} lg={3}>
                        <div className="card-contains">
                            <div style={{ textAlign: 'center', fontSize: 40 }}>
                                <SnippetsOutlined />
                            </div>
                            <h2 style={{ textAlign: 'center', fontSize: 32  }}>Garantie</h2>
                            <p style={{ fontSize: 24, textAlign: 'center', paddingTop: 10 }}>
                                Assurance multirisque professionnel et garantie décennale
                            </p>                
                        </div>
                    </ColBootsrap>
                </RowBoostrap>
            </Container>
            {/* <div className="tartan" style={{ minHeight: '35vh' }}>
                <Row style={{ paddingLeft: largeScreen() ? 50 : 0, paddingTop: 20 }}>
                    <Col xs={20} md={5} offset={largeScreen() ? 0 : 2} className="card-contains" style={{ marginBottom: largeScreen() ? 0: 40}}>
                        <div style={{ textAlign: 'center', fontSize: 30, marginBottom: 10 }}>
                            <StarFilled />
                            <StarFilled />
                            <StarFilled />
                            <StarFilled />
                            <StarFilled />
                        </div>
                        <h2 style={{ textAlign: 'center', fontSize: 32  }}>Expérience</h2>
                        <p style={{ fontSize: 24, textAlign: 'center', paddingTop: 10 }}>
                            Plus de 20 ans d’expérience dans la plomberie, Pierrotechnic vous assure un suivi et un travail sur mesure
                        </p>
                    </Col>
                    <Col xs={20} md={5} offset={largeScreen() ? 1 : 2} className="card-contains" style={{ marginBottom: largeScreen() ? 0: 40}}>
                        <div style={{ textAlign: 'center', fontSize: 40 }}>
                            <FieldTimeOutlined />
                        </div>
                        <h2 style={{ textAlign: 'center', fontSize: 32  }}>Efficacité</h2>
                        <p style={{ fontSize: 24, textAlign: 'center', paddingTop: 10 }}>
                            Prise de RDV dans les plus brefs délais et intervention rapide
                        </p>
                    </Col>
                    <Col xs={20} md={5} offset={largeScreen() ? 1 : 2} className="card-contains" style={{ marginBottom: largeScreen() ? 0: 40}}>
                        <div style={{ textAlign: 'center', fontSize: 40 }}>
                            <ToolOutlined />
                        </div>
                        <h2 style={{ textAlign: 'center', fontSize: 32  }}>Travail soigné</h2>
                        <p style={{ fontSize: 24, paddingTop: 10, textAlign: 'center' }}>
                            A l'écoute du client, outillage adapté, exécution en toute sécurité
                        </p>
                    </Col>
                    <Col xs={20} md={5} offset={largeScreen() ? 1 : 2} className="card-contains">
                        <div style={{ textAlign: 'center', fontSize: 40 }}>
                            <SnippetsOutlined />
                        </div>
                        <h2 style={{ textAlign: 'center', fontSize: 32  }}>Garantie</h2>
                        <p style={{ fontSize: 24, textAlign: 'center', paddingTop: 10 }}>
                            Assurance multirisque professionnel et garantie décennale
                        </p>
                    </Col>
                </Row>
            </div> */}
            <RowBoostrap style={{ marginTop: 100, marginBottom: 100 }}>
                <ColBootsrap xs={12}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Image src={`${domain}/media/media/site/optimize/logo_with_tartan.png`} preview={false} style={{ borderRadius: 30 }} width={300}/>
                    </div>
                    <h1 style={{ width: '100%', marginTop: 50, textAlign: 'center', fontSize: 28 }}>Soucis à la boutique ?</h1>
                    <h1 style={{ width: '100%', marginLeft: 0, textAlign: 'center', fontSize: 28 }}>Appelez Pierrotechnic !</h1>
                </ColBootsrap>
            </RowBoostrap>
            {/* <Row style={{ minHeight: '40vh', marginBottom: 100 }}>
                <Col xs={24} md={12} offset={largeScreen() ? 6 : 0} style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', paddingTop: 100 }}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Image src="https://entiouct.sirv.com/pierrotechnic/site/logo_with_tartan.png?w=800&h=652" width={largeScreen() ? 400 : 200} preview={false} style={{ borderRadius: 30 }} />
                    </div>
                    <h1 style={{ width: '100%', marginTop: 50, textAlign: 'center', fontSize: largeScreen() ? 40:28 }}>Soucis à la boutique ?</h1>
                    <h1 style={{ width: '100%', marginLeft: 0, textAlign: 'center', fontSize: largeScreen() ? 40:28 }}>Appelez Pierrotechnic...</h1>
                </Col>
            </Row> */}
            <Footer />
            {
                /**
                 * MODAL
                 */
            }
            <ModalEnc open={isModalOpen} fn={setIsModalOpen} />
        </div>
    )
}

export default Home