import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Divider, Collapse, Image, Row , Col, Empty, Spin, Button, Tag, Form, Input } from 'antd'
import axios from 'axios';
import { CloseCircleOutlined } from '@ant-design/icons';
import u from 'underscore'
import moment from 'moment'

const { TextArea } = Input
const { Panel } = Collapse;

const Header = ({ client_info, reference, date }) => {
    return (
        <div>
            <span>
                {client_info.lastname} {client_info.firstname}
            </span>
            <Divider type='vertical' />
            <span>
                {client_info.zipcode}
            </span>
            <Divider type='vertical' />
            <span>
                {client_info.phone}
            </span>
            <Divider type='vertical' />
            <span>
                {reference}
            </span>
            <Divider type='vertical' />
            <span>
                {moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a")}
            </span>
        </div>
    )
}

const CommentForm = ({ intervention }) => {
    const [note, setNote] = useState(intervention.intervention.notes)
    const [loading, setLoading] = useState(false)
    const onFinish = (values) => {
        setLoading(true)
        const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
        const url = `${domain}/api/v1/intervention/update/notes/${intervention.intervention.id}`
        axios({
            method:'post', 
            url: url, 
            data: { 
                notes: values.comment
            },
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFTOKEN',
            withCredentials: true
        })
        .then(response => {
            if (response.status === 200) {
                setNote(values.comment)
                setLoading(false)
            }
        })
        .catch(error => {
            console.log("there is an error ==========")
            console.log(error)
            return ;
        });
    }
    return (
        <>
            <Form onFinish={onFinish} initialValues={{ comment: note }}>
                <Form.Item label="Commentaires" name="comment">
                    <TextArea rows={4} placeholder='votre commentaire ici'/>
                </Form.Item>
                <Form.Item className='flex-end'>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Commenter
                    </Button>
                </Form.Item> 
            </Form>
        </>
    )
}

const Extra = forwardRef(( props, ref ) => {
    const [status, setStatus] = useState(props.intervention.intervention.status)
    const [domain, ] = useState(process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD)
    useImperativeHandle(ref, () => ({
        status: status,
        updateStatusInter(s) {
            const url = `${domain}/api/v1/intervention/update/status/${props.intervention.intervention.id}`
            axios({
                method:'post', 
                url: url, 
                data: { 
                    status: s
                },
                xsrfCookieName: 'csrftoken',
                xsrfHeaderName: 'X-CSRFTOKEN',
                withCredentials: true
            })
            .then(response => {
                if (response.status === 200) {
                    setStatus(s)
                }
            })
            .catch(error => {
                console.log("there is an error ==========")
                console.log(error)
                return ;
            });
        }
    }))
    const getProperColor = () => {
        if (status === "NOT_READ") {
            return 'magenta'
        } else if (status === "REJECTED") {
            return 'red'
        } else if (status === "VALIDATE") {
            return 'green'
        } else {
            return ''
        }
    }
    const deleteInterFromInterventions = (id) => {
        let newData = props.data.filter(item => item.intervention.id !== id)
        props.setInterventions(newData)
    }
    const deleteIntervention = (e, id) => {
        e.stopPropagation()
        const url = `${domain}/api/v1/intervention/delete/${id}`
        axios({
            method:'post', 
            url: url, 
            data: { 
            },
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFTOKEN',
            withCredentials: true
        })
        .then(response => {
            if (response.status === 200) {
                deleteInterFromInterventions(id)
            }
        })
        .catch(error => {
            console.log("there is an error ==========")
            console.log(error)
            return ;
        });
    }
    return (
        <>
            <Tag color={getProperColor()}>{status}</Tag>
            <Divider type="vertical" />
            <CloseCircleOutlined style={{ color: 'red' }} onClick={(e) => deleteIntervention(e, props.intervention.intervention.id)}/>
        </>
    )
})

const Messages = () => {
    const [interventions, setInterventions] = useState([])
    const [loading, setLoading] = useState(false)
    const [domain, ] = useState(process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD)
    const ref = useRef([])

    useEffect(() => {
        ref.current = ref.current.slice(0, interventions.length);
     }, [interventions]);

    useEffect(() => {
        // call api to intervention
        setLoading(true)
        axios
            .get(`${domain}/api/v1/get_all_interventions`)
            .then((res) => {
                setInterventions(res.data.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }, []) 
    const onChange = (key) => {
        if (key.length === 1) {
            if (ref.current[key].status === 'NOT_READ') {
                ref.current[key].updateStatusInter("READ")
            }
        }
    };
    const changeStatusValidated = (key) => {
        if (ref.current[key].status === 'READ') {
            ref.current[key].updateStatusInter("VALIDATE")
        }
    }
    const changeStatusRejected = (key) => {
        if (ref.current[key].status === 'READ') {
            ref.current[key].updateStatusInter("REJECTED")
        }
    }
    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Messages</h1>
            <Divider />
            {
                loading ? (<div className='flex-center'><Spin size='large' /></div>) : (
                        interventions.length > 0 ? (
                            <Collapse onChange={onChange}>
                                {
                                    u.sortBy(interventions, (item) => item.intervention.created_at).reverse().map((intervention, id) => {
                                        return (
                                            <Panel key={id} header={<Header client_info={intervention.client} reference={intervention.intervention.reference} date={intervention.intervention.created_at}/>} extra={<Extra ref={el => ref.current[id] = el} intervention={intervention} data={interventions} setInterventions={setInterventions} />}>
                                                <Row>
                                                    {
                                                        intervention.images ? (
                                                            <Col xs={6}>
                                                                <Image src={`${domain}${intervention.images.url}`}/>                                            
                                                            </Col>
                                                        ) : (null)
                                                    }
                                                    <Col xs={12} offset={1}>
                                                        <p>{intervention.message.message}</p>
                                                        {
                                                            intervention.intervention.status === "VALIDATE" ? (
                                                                <CommentForm intervention={intervention} />
                                                            ) : (
                                                                intervention.intervention.status === 'REJECTED' ? (null) : (
                                                                    <>
                                                                        <div>
                                                                            <Button type='default' danger onClick={() => changeStatusRejected(id)}>REJETER</Button>
                                                                            <Divider type='vertical' />
                                                                            <Button type='default' ghost={true} style={{ color: '#2cb02a', borderColor: '#2cb02a'}} onClick={() => changeStatusValidated(id)}>VALIDER</Button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            )
                                                        }
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        )
                                    })
                                }
                            </Collapse>
                        ) : (<Empty />)

                )
            }
        </div>
    )
}


export default Messages