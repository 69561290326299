import React from 'react'

const Mentions = () => {
    return (
        <div style={{ padding: 20 }}>
            <h1>Identification de l'éditeur</h1>
            <p style={{ fontSize: 20 }}>Pierrotechnic, 27 rue du bourg pillery 08270 Séry</p>
            <p style={{ fontSize: 20 }}>Téléphone: 06 30 85 33 18</p>
            <p style={{ fontSize: 20 }}>Email: pierrotechnic@gmail.com</p>
            <p style={{ fontSize: 20 }}>Site Web: https://pierrotechnic.fr</p>
            <p style={{ fontSize: 20 }}>RCS: 911317014</p>
            <p style={{ fontSize: 20 }}>SIRET: 91131701400015</p>
            <h1>Hébergement</h1>
            <p style={{ fontSize: 20 }}>Pierrotechnic est héberger par Amazon France, dont le siège social se situe, 67 Boulevard du Général Leclerc, 92110 Clichy, France</p>
            <h1>Traitement des données à caractère personnel</h1>
            <p style={{ fontSize: 20 }}>Pierrotechnic n'utilise pas de cookie.</p>
            <p style={{ fontSize: 20 }}>Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui vous concernent. Pour demander une modification, rectification ou suppression des données vous concernant, il vous suffit d'envoyer un courrier par voie électronique ou postale à Pierrotechnic en justifiant de votre identité.</p>
        </div>
    )
}

export default Mentions