import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { Button, Steps } from 'antd';
import CreateClient from './CreateClient';
import CreateMessagesForIntervention from './CreateMessageForIntervention';
import CreateImagesForIntervention from './CreateImagesForIntervention';

const steps = [
  {
    title: 'Information',
    content: (props) => <CreateClient {...props} />,
  },
  {
    title: 'Intervention',
    content: (props) => <CreateMessagesForIntervention {...props} />,
  },
  {
    title: 'Upload',
    content: (props) => <CreateImagesForIntervention {...props} />,
  },
  {
    title: 'Fin',
    content: (props) => <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}><h2>Votre numéro de dossier est le :</h2><h1>{props.intervention_reference}</h1><h3>Référence à rappeler lors de la prise de rendez-vous</h3><Button type='default' onClick={() => props.handleTerminated()} style={{ marginTop: 20 }}>Terminer</Button></div>
  }
];

const Conception = ({ csrf, fn }) => {
    const [current, setCurrent] = useState(0);
    const [idOfClient, setIdOfClient] = useState(null)
    const [idOfMessage, setIdOfMessage] = useState(null)
    const [intervention_reference, setInterventionReference] = useState(null)

    const next = () => {
        setCurrent(current + 1);
    };
    const items = steps.map((item) => ({
      key: item.title,
      title: item.title,
    }));
    const contentStyle = {
      minHeight: '260px',
      marginTop: 16,
    };

    const handleTerminated = () => {
      fn(false)
    }
    return (
        <div className="">
            <Row style={{ paddingTop: 60 }}>
                <Col xs={18} offset={3}>
                    <Steps current={current} items={items} />
                </Col>
            </Row>
            <Row>            
                <Col xs={24}>
                    <div style={contentStyle}>{steps[current].content({ intervention_reference, setInterventionReference, csrf: csrf, submitNext: next, idOfClient: idOfClient, setIdOfClient: setIdOfClient, handleTerminated: handleTerminated, idOfMessage, setIdOfMessage })}</div>
                </Col>
            </Row>
        </div>
    )
}

export default Conception