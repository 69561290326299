import { useEffect, useState } from 'react';
import React from "react";
import MenuHome from './Menu'
import ModalEnc from "./ModalEnc";
import { Row as RowBoostrap, Col as ColBootsrap, Container } from 'react-bootstrap'
import { Image, Row, Col, Grid } from 'antd';
import { FacebookOutlined } from '@ant-design/icons';
import Footer from './Footer';
const { useBreakpoint } = Grid;

const Galerie = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [galerie, setGalerie] = useState([])
    const [screenSize, setScreenSize] = useState('xs')
    const screens = useBreakpoint();

    useEffect(() => {
        let i = 0;
        let arr = []
        const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
        while(i < 24) {
            arr.push(`${domain}/media/media/galerie/optimize/img_${i}.jpg`)
          i++
        }
        setGalerie(arr)
    }, [])
    return (
        <>
            <MenuHome setModalOpen={setIsModalOpen} />
            {/* {
                galerie === [] ? (<>NO DATA</>) : (
                    <RowBoostrap>
                        {
                            galerie.map((item, id) => {
                                return (
                                    <ColBootsrap xs={12} md={4}>
                                        <Image src={item} key={id}/>
                                    </ColBootsrap>
                                )
                            })
                        }
                    </RowBoostrap>
                )
            } */}
            {
                galerie === [] ? (<>NO DATA</>) : (
                    <Row>
                        {
                            galerie.map((item, id) => {
                                return (
                                    <Col xs={24} md={4} key={id}>
                                        <Image src={item} key={id} />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                )
            }
            <Footer />
            {
            /**
             * MODAL
             */
            }
            <ModalEnc open={isModalOpen} fn={setIsModalOpen} />
        </>
    )
}

export default Galerie