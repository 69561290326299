import React from 'react'
import { Link } from 'react-router-dom'
import { FacebookOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import '../css/footer.css'

const Footer = () => {
    return (
        <div className="flex-center-col " style={{ backgroundColor: '#333', minHeight: '20vh', paddingTop: 40, paddingBottom: 40 }}>
            <Link to="/mentions-legales" className="liens-footer">mentions légales</Link>
            <div style={{ fontSize: 30, color: 'white', cursor: 'pointer' }}>
                <a href="https://www.facebook.com/profile.php?id=100089307673980" className="liens-footer-fb"><FacebookOutlined /></a>
            </div>
        </div>
    )
}

export default Footer