import React, { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom";
import { CsrfContext } from './CsrfContext';
import axios from 'axios'
import { ThemeProvider } from 'react-bootstrap';

const Root = () => {    
    return (
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
        >
            <Outlet />
        </ThemeProvider>
    )
}

export default Root