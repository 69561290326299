import React from "react";
import { Layout } from "antd";
import { MailOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Outlet, useNavigate } from "react-router-dom";

const { Sider } = Layout

const router = [
    {
        name: "clients",
        path: '/dashboard/clients'
    },
    {
        name: "messages",
        path: '/dashboard/messages'
    },
    {
        name: "settings",
        path: '/dashboard/settings'
    },
]
function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
}

const items = [
    // getItem('Clients', 'clients', <UserOutlined />),
    getItem('Messages', 'messages', <MailOutlined />),
    // getItem('Settings', 'settings', <SettingOutlined />),
    {
        type: 'divider',
    },  
    getItem('Logout', 'logout', <LogoutOutlined />),
];

const Dashboard = () => {
    const navigate = useNavigate()

    const onClick = (e) => {
        if (e.key == 'messages') {
            navigate(router[1].path)
        } else if (e.key == 'settings') {
            navigate(router[2].path)
        } else if (e.key == 'clients') {
            navigate(router[0].path)
        }
         else if (e.key == 'logout') {
            localStorage.clear()
            navigate('/admin')
        }
    };
    return (
        <Layout>
            <Sider style={{ minHeight: '100vh', color: 'white', paddingTop: 60, position: 'fixed' }}>
                <Menu
                    onClick={onClick}
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    theme="dark"
                    items={items}
                />
            </Sider>
            <div style={{ padding: 40, width: '100%', marginLeft: 200 }}>
                <div style={{ border: '1px solid #e8e8e8', width: '100%', minHeight: window.innerHeight - 80, backgroundColor: 'white', padding: 30 }}>
                    <Outlet />
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard