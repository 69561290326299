import React, { useState } from 'react'
import { Form, Button, Input, Row } from 'antd'
import axios from 'axios'

const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
};

const CreateClient = ({ submitNext, setIdOfClient }) => {
    const [error, setError] = useState(null)
    const checkZipcode = (zip) => {
        if (zip.length === 5) {
            if (zip[0] === '0' && zip[1] === '8') {
                return true
            }
            if (zip[0] === '5' && zip[1] === '1') {
                return true
            }
            if (zip[0] === '0' && zip[1] === '2') {
                return true
            }
            setError("le code postal ne fait pas parti de la zone d'intervention")
            return false
        } else {
            setError('Code postal invalide')
        }
        return false
    }
    const onFinish = (values) => {
        if (checkZipcode(values.zipcode)) {
            const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
            const url = `${domain}/api/v1/client/create`
            axios({
                method:'post', 
                url: url, 
                data: { 
                    firstname: values.firstname,
                    lastname: values.lastname,
                    email: values.email,
                    phone: values.phone,
                    zipcode: values.zipcode
                },
                xsrfCookieName: 'csrftoken',
                xsrfHeaderName: 'X-CSRFTOKEN',
                withCredentials: true
            })
            .then(response => {
                if (response.status === 200) {
                    setIdOfClient(response.data.uuid)
                    submitNext()
                }
            })
            .catch(error => {
                console.log("there is an error ==========")
                console.log(error)
                return ;
            });
        }
    }
    return (
        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 40, flexDirection: 'column' }}>
            {
                error !== null ? (
                    <div style={{ marginBottom: 30, color: 'red' }}>{error}</div>
                ) : (null)
            }
            <Form onFinish={onFinish} {...formItemLayout}>
            <Form.Item
                    name="lastname"
                    label="Nom"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de renseigner votre nom.',
                        },
                    ]}
                >
                    <Input placeholder='Nom de famille'/>
                </Form.Item>
                <Form.Item
                    name="firstname"
                    label="Prénom"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de renseigner votre prénom.',
                        },
                    ]}
                >
                    <Input placeholder='Votre prénom' />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de renseigner votre mail.',
                        },
                    ]}
                >
                    <Input placeholder='Votre email' />
                </Form.Item>
                <Form.Item
                    name="zipcode"
                    label="Code postal"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de renseigner votre code postal.',
                        },
                    ]}
                >
                    <Input placeholder='Votre code postal' onChange={() => setError(null)}/>
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Téléphone"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de renseigner votre téléphone.',
                        },
                    ]}
                >
                    <Input placeholder='Votre téléphone' />
                </Form.Item>
                <Form.Item className='flex-end'>
                    <Button type="primary" htmlType="submit">
                        Suivant
                    </Button>
                </Form.Item>            
            </Form>
        </Row>
    )
}

export default CreateClient