import React, { useEffect, useState } from 'react'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Image, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { Row as RowBootsrap, Col as ColBootsrap, Container } from 'react-bootstrap';

import '../css/Menu.css'


// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const MenuHeader = ({ setModalOpen }) => {
    const screen = useWindowSize()
    const onClickModal = (e) => {
        setModalOpen(true)
    };
    const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
    return (
      <div style={{ backgroundColor: '#18325c', color: 'white', paddingTop: 20 }}>
        <Container fluid>
          <RowBootsrap>
            <RowBootsrap>
                  {
                    // sur tablet, pc, tv, et all support browser => bootstrapv5
                  }
                      <ColBootsrap  xs={2} md={3} lg={3} xl={2} xxl={2} style={{ textAlign: 'center' }} className='flex-center-col d-none d-xs-none d-sm-none d-md-block'>
                          <Image src={`${domain}/media/media/site/optimize/logo.png`}  preview={false} alt="" width={200}/>
                      </ColBootsrap>
                      <ColBootsrap xs={6} md={4} lg={4} xl={6} xxl={6} style={{ marginTop: 60 }} className='d-none d-xs-none d-sm-none d-md-none d-lg-block'>
                              <h1 style={{ fontWeight: 'bold' }}>PIERROTECHNIC</h1>
                              <h3>Plomberie - Chauffage</h3>
                      </ColBootsrap>
                      <ColBootsrap xs={6} md={4} lg={4} xl={6} xxl={6} style={{ marginTop: 60 }} className='d-none d-xs-none d-sm-none d-md-block d-lg-none'>
                              <h2 style={{ fontWeight: 'bold' }}>PIERROTECHNIC</h2>
                              <h4>Plomberie - Chauffage</h4>
                      </ColBootsrap>
                      <ColBootsrap xs={12} md={5} lg={5} xl={4} xxl={4}  style={{ textAlign: 'right', marginTop: 40 }} className='d-none d-xs-none d-md-block'>
                            <button className='button-23' onClick={() => onClickModal() }>Demander une intervention</button>
                            <div className="d-md-block" style={{ marginTop: 20 }}>
                                <h3 style={{ fontSize: 22, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'  }}><MailOutlined style={{ marginRight: 10 }}/> <span>pierrotechnic@gmail.com</span></h3>
                                <h3 style={{ fontSize: 22, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}><PhoneOutlined style={{ marginRight: 10, rotate: '90deg' }}/> <span>06 30 85 33 18</span></h3>
                            </div>              
                      </ColBootsrap>
                  {
                    // sur mobile
                  }
                  <ColBootsrap className="d-xs-block d-sm-block d-md-none">
                      <RowBootsrap>
                        <ColBootsrap className='flex-center'>
                              <Image src={`${domain}/media/media/site/optimize/logo.png`}  preview={false} alt="" width={200}/>
                              <div className='flex-center-col'>
                                <h1 style={{ fontWeight: 'bold' }}>PIERROTECHNIC</h1>
                                <h4>Plomberie - Chauffage</h4>
                              </div>
                        </ColBootsrap>
                      </RowBootsrap>
                      <RowBootsrap>
                        <ColBootsrap style={{ textAlign: 'center', marginTop: 20, marginLeft: 24 }}>
                          <button className='button-23' onClick={() => onClickModal() }>Demander une intervention</button>
                        </ColBootsrap>
                      </RowBootsrap>
                      <RowBootsrap>
                        <ColBootsrap xs={12} style={{ textAlign: 'center', marginTop: 20 }}>
                            <div className="" style={{ marginTop: 20 }}>
                                <h2 style={{ fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'flex-end'  }}><MailOutlined style={{ marginRight: 10 }}/> <span>pierrotechnic@gmail.com</span></h2>
                                <h2 style={{ fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'  }}><PhoneOutlined style={{ marginRight: 10, rotate: '90deg' }}/> <span>06 30 85 33 18</span></h2>
                            </div>
                            <Divider style={{ backgroundColor: 'white' }}/>
                        </ColBootsrap>
                      </RowBootsrap>
                  </ColBootsrap>
            </RowBootsrap>
          </RowBootsrap>
          <Container>
            <RowBootsrap style={{ marginBottom: 20 }}>
              <ColBootsrap className='flex-center'>
                  <div className='decoratorLink'><Link to="/"  style={{ fontSize: 24 }}>Accueil</Link></div>
                  {
                    screen.width >= 992 ? (
                      <Divider type='vertical' style={{  marginRight: 30, marginLeft: 30 }} />
                    ) : (
                      <Divider type='vertical' />
                    )
                  }
                  <div className='decoratorLink'><Link to="/entreprise" style={{ fontSize: 24 }}>Entreprise</Link></div>
                  {
                    screen.width >= 992 ? (
                      <Divider type='vertical' style={{  marginRight: 30, marginLeft: 30 }} />
                    ) : (
                      <Divider type='vertical' />
                    )
                  }
                  <div className='decoratorLink'><Link to="/galerie" style={{ fontSize: 24 }}>Galerie</Link></div>              
              </ColBootsrap>
            </RowBootsrap>
          </Container>
        </Container>
        <div style={{ backgroundColor: '#a9c1e8', height: 10, marginTop: 10 }}></div>
      </div>
    )
}
      // <div className='menu-general'>
        
        /* <Row>
            {
              largeScreen() ? (
                  <>
                    <Row style={{ width: '100%', color: 'white' }}>
                      <Col xs={24} md={8} offset={0} className="flex-center">
                          <img src="https://entiouct.sirv.com/pierrotechnic/site/logo.png" className="logo" alt="" />
                          <div>
                              <div className='title' style={{ fontWeight: 'bold' }}>PIERROTECHNIC</div>
                              <div className='subtitle'>Plomberie - Chauffage</div>
                          </div>
                      </Col>
                      <Col xs={0} md={8} offset={0} className="">
                      </Col>
                      <Col xs={24} md={8} offset={0} className="flex-center">
                          <button className='button-23' onClick={() => onClickModal() }>Demander une intervention</button>
                      </Col>
                    </Row>
                    <Row style={{ width: '100%', color: 'white' }}>
                        <Col xs={24} md={8} offset={8} className="flex-center">
                          <div className='decoratorLink'><Link to="/"  style={{ fontSize: 24, marginRight: 40 }}>Accueil</Link></div>
                          <div className='decoratorLink' style={{ marginRight: 60 }}><Link to="/entreprise" style={{ fontSize: 24 }}>Entreprise</Link></div>
                          <div className='decoratorLink'><Link to="/galerie" style={{ fontSize: 24 }}>Galerie</Link></div>
                        </Col>
                        <Col xs={24} md={8} offset={0} className='flex-end' style={{ paddingRight: 50 }}>
                          <div className='infos'>
                              <h2 style={{ fontSize: 26 }}><MailOutlined /> pierrotechnic@gmail.com</h2>
                              <h2 style={{ fontSize: 26 }}><PhoneOutlined /> 06 30 85 33 18</h2>
                          </div>
                        </Col>
                    </Row>
                </>                
              ) : (
                <>
                  <Row>
                    <Col xs={10}>
                        <img alt="" src="https://entiouct.sirv.com/pierrotechnic/site/logo.png" width='80%'/>
                    </Col>
                    <Col xs={10} className='flex-center-col'>
                      <div style={{ color: 'white' }}>
                          <div style={{ fontWeight: 'bold', fontSize: 26 }}>PIERROTECHNIC</div>
                          <div style={{ fontSize: 20 }}>Plomberie - Chauffage</div>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{marginBottom: 30, textAlign: 'center', width: '100%' }}>
                    <Col xs={20} offset={2} className="flex-center">
                      <button className='button-24' onClick={() => onClickModal() }>Demander une intervention</button>
                    </Col>
                  </Row>
                  <Row style={{ width: '100%', marginBottom: 10 }}>
                    <Col xs={24} className='flex-center'>
                          <div className='decoratorLink'><Link to="/"  style={{ fontSize: 18, marginRight: 20 }}>Accueil</Link></div>
                          <div className='decoratorLink' style={{ marginRight: 40 }}><Link to="/entreprise" style={{ fontSize: 18 }}>Entreprise</Link></div>
                          <div className='decoratorLink'><Link to="/galerie" style={{ fontSize: 18 }}>Galerie</Link></div>
                    </Col>
                  </Row>
                </>
              )
            }
        </Row> */

export default MenuHeader