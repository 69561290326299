import React, { useState } from 'react'
import { Form, Button, Input, Row } from 'antd'
import axios from 'axios'

const CreateMessagesForIntervention = ({ idOfClient, submitNext, setIdOfMessage }) => {
    const [error, setError] = useState(null)
    const form = Form.useForm()
    const onFinish = (values) => {
        if (values.message) {
            const domain = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
            const url = `${domain}/api/v1/message/create`
            axios({
                method:'post', 
                url: url, 
                data: { 
                    client_uuid: idOfClient,
                    message: values.message.msg
                },
                xsrfCookieName: 'csrftoken',
                xsrfHeaderName: 'X-CSRFTOKEN',
                withCredentials: true
            })
            .then(response => {
                if (response.status === 200) {
                    setIdOfMessage(response.data.message_id)
                    submitNext()
                }
            })
            .catch(error => {
                console.log("there is an error ==========")
                console.log(error)
                return ;
            });
        } else {
            setError('Merci de bien vouloir renseigner ce champs')
        }
    }
    const onInputChange = () => {
        setError(null)
    }
    return (
        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 40, width: '100%' }}>
            <h2>Un message descriptif de votre situation</h2>
            {
                error ? (error) : (null)
            }
            <Form onFinish={onFinish}>
                <Form.List
                    name="message"
                >
                    {
                        (fields) => (
                            <div>
                                <Form.Item
                                    name="msg"
                                    className='d-none d-xs-none d-sm-none d-md-none d-lg-block'
                                >   
                                    <Input.TextArea rows={6} style={{ width: 600 }} onChange={onInputChange} placeholder='Votre  message, max. 400 characters' maxLength={400}/>
                                </Form.Item>
                                <Form.Item
                                    name="msg"
                                    className='d-none d-xs-none d-sm-block d-md-block d-lg-none'
                                >   
                                    <Input.TextArea rows={6} style={{ width: 400 }} onChange={onInputChange} placeholder='Votre  message, max. 400 characters' maxLength={400}/>
                                </Form.Item>
                                <Form.Item
                                    name="msg"
                                    className='d-sm-none'
                                >   
                                    <Input.TextArea rows={6} style={{ width: 250 }} onChange={onInputChange} placeholder='Votre  message, max. 400 characters' maxLength={400}/>
                                </Form.Item>
                            </div>
                        )
                    }
                </Form.List>

                <Form.Item style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit">
                        Suivant
                    </Button>
                </Form.Item>            
            </Form>
        </Row>
    )    
}

export default CreateMessagesForIntervention